module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"paulmoysan.io","short_name":"paulmoysan.io","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/seal.jpeg","icons":[{"src":"src/images/background.jpeg","type":"image/jpeg","sizes":"192x192"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5b0001815c6db714ca1378730824ccf3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
